<template>
	<v-dialog
    v-model="paramsDialog.estatus"
    hide-overlay
    persistent
    max-width="1000"
  >
    <v-card>
      <v-card-title primary-title >
        Asignar curso
      </v-card-title>
      <v-card-text >

      	<v-col cols="12" md="6" lg="4">
      		<v-checkbox
			      v-model="recepcion"
			      label="Recepcionistas"
			      dense
			      hide-details
			    ></v-checkbox>
			    <v-checkbox
			      v-model="encargadas"
			      label="Encargadas"
			      dense
			      hide-details
			    ></v-checkbox>
			    <v-checkbox
			      v-model="coordinadoras"
			      label="Coordinadoras"
			      dense
			    ></v-checkbox>

			    <v-autocomplete
            filled
            dense
            clearable
            v-model="puesto"
            :items="puestos"
            label="Selecciona un puesto"
            persistent-hint
            hide-details
            item-text="puesto"
            item-value="idpuesto"
          >
          </v-autocomplete>

      	</v-col>

      	<v-col cols="12" sm="6" md="6" lg="6" xl="6" class="pb-0">
          <v-text-field
            v-model="search"
            dense
            rounded
            filled
            single-line
            label="Buscar"
            append-icon="mdi-magnify"
          ></v-text-field>  
        </v-col>

        <v-data-table
          :headers="headerColaboradores"
          :items="trabajadorERP"
          class="elevation-0"
          dense
          :search="search"
          v-model="usuariosCapacitar"
			    item-key="id_trabajador"
			    show-select
        >
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-btn 
        	color="black" 
        	tile 
        	dark 
        	@click="cerrar()"
        	small
        >
        	<v-icon left small>mdi-cancel</v-icon>
      		Cancelar
      	</v-btn>
        <v-spacer></v-spacer>
        <v-btn 
        	color="green" 
        	tile 
        	dark 
        	@click="saveVideoUsuario()"
        	small
        >
        	<v-icon left small>mdi-content-save</v-icon>
      		Guardar
      	</v-btn>
      </v-card-actions>
      <v-btn 
        color="transparent elevation-0"
        x-small
        absolute
        top
        right
        fab
        class="mt-5 mr-0"
        @click="cerrar()"
       ><v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-dialog>
</template>
<script>
import axios from 'axios';

	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import { mapGetters, mapActions }  from 'vuex';
  import funcionesExcel        from '@/mixins/funcionesExcel'


	export default {

		components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

		props:[
			'paramsDialog',
			'curso'
	  ],

    computed: {
      ...mapGetters('login', ['getdatosUsuario', 'getLogeado', 'getEscuela','drawer2','getEstatusLlamada','getNuevosProspectos']),
    },

    data: () => ({

    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

			usuarios:[],
			puesto: null,
			puestos: [],

      headerColaboradores: [
        { text: 'Folio'      , value: 'id_trabajador'    },
        { text: 'Nombres'    , value: 'nombres'          },  
        { text: 'Apellido P.', value: 'apellido_paterno' },  
        { text: 'Apellido M.', value: 'apellido_materno' },  
        { text: 'Puesto'     , value: 'puesto'           },  
      ],
      search:'',

      recepcion: null,
			encargadas: null,
			coordinadoras: null,
			trabajadorERP:[],

			usuariosCapacitar:[],

    }),


    watch:{

    	recepcion( value ){

    		if( value ){

    			this.usuariosCapacitar = this.usuariosCapacitar.concat( this.trabajadorERP.filter( el => el.id_puesto == 19 ) )

    		}else{

    			this.usuariosCapacitar = this.usuariosCapacitar.filter( el => el.id_puesto != 19 )

    		}

    	},

			encargadas( value ){

				if( value ){

					this.usuariosCapacitar = this.usuariosCapacitar.concat( this.trabajadorERP.filter( el => el.id_puesto == 18 ) )

				}else{

					this.usuariosCapacitar = this.usuariosCapacitar.filter( el => el.id_puesto != 18 )

				}

    	},

			coordinadoras( value ){

				if( value ){

					this.usuariosCapacitar = this.usuariosCapacitar.concat( this.trabajadorERP.filter( el => el.id_puesto == 73 ) )

				}else{

					this.usuariosCapacitar = this.usuariosCapacitar.filter( el => el.id_puesto != 73 )

				}

    	},

    },

    created(){
    	this.initialize()
    	this.getPuestos( )
    },


    methods:{

      initialize () {  
        this.cargar = true
        this.trabajadorERP = []
        return this.$http.get('trabajadores.erp.get.trabajadoreserp').then(response=>{
   
          this.trabajadorERP = response.data.filter( el => el.activo_sn == 1)

          this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      cerrar(){
      	this.paramsDialog.estatus = false
      },

      getPuestos( ){
	      this.puestos = []
	      this.cargar = true
	      return this.$http.get('puestos.all').then(response=>{
	        this.cargar = false
	        this.puestos = response.data
	        // Traer los puestos
	      }).catch( error =>{
	        this.validarError( error.response.data.message )
	      }).finally( () => { this.cargar = false })
	    },

      exportar( ){

        this.exportExcel( this.prospectosCerrados  , 'prospectosCerrados')
      },


      saveVideoUsuario( ){

      	const payload = {

      		idcurso : this.curso.idvideos_capa,
      		usuarios: this.usuariosCapacitar
      	}
         
        this.cargar = true

      	// Agregar un material
        this.$http.post("videos.capa.usuarios", payload ).then((response) => {
          this.cargar = false
          this.usuariosCapacitar = []
          this.validarSuccess('Cursos agregados')
        }).catch( error =>{
          this.validarError( error )
        }).finally( () => { this.cargar = false })

      },

      validarSuccess( mensaje ){
	      this.cargar = false
	      this.parametros.mensaje      = mensaje
	      this.parametros.dialogError  = true 
	      this.parametros.color        = 'success'
	      this.respuestaAlerta         = true 
	    },

	    validarError ( value ) {
	      this.cargar = false 
	      if( value.status == 400 || value.status == 500){
	        this.parametros.mensaje      = value.body.message
	        this.parametros.dialogError  = true 
	        this.parametros.color        = '#FF2C2C'
	        this.respuestaAlerta = true 
	      }
	    },

	    validarErrorDirecto ( mensaje ) {
	      this.cargar = false 
	      this.parametros.mensaje      = mensaje
	      this.parametros.dialogError  = true 
	      this.parametros.color        = '#FF2C2C'
	      this.respuestaAlerta = true 
	    },
    }
	}
</script>
