<template>
  <v-app>
    <v-app-bar app color="primary" dark height="56px" v-if="getLogeadoCapa">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      Panel de capacitación
      <v-spacer></v-spacer>
      <v-btn color="white" icon @click="salir"><v-icon>mdi-exit-to-app</v-icon></v-btn>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant.sync="mini"
      app
      v-if="getLogeadoCapa"
    >
      <v-list-item class="px-2">
        <v-list-item-avatar>
          <v-img
            :src=" avatar + getUsuarioCapa.nombre_completo + getUsuarioCapa.apellido "
          ></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>
            {{ getUsuarioCapa.nombre_usuario + " " + getUsuarioCapa.apellido_usuario }}
          </v-list-item-title>
          
          <v-list-item-subtitle>
            {{ getUsuarioCapa.puesto }}
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-btn icon @click.stop="mini = !mini">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav >
        <v-list-item
          color="primary"
          v-for="item in menu"
          :key="item.title"
          link
          :to="{ name: item.path }"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

//bloquea el clic derecho
function disableIE() {
  if (document.all) {
    return false;
  }
}
function disableNS(e) {
  if (document.layers || (document.getElementById && !document.all)) {
    if (e.which == 2 || e.which == 3) {
      return false;
    }
  }
}
if (document.layers) {
  document.captureEvents(Event.MOUSEDOWN);
  document.onmousedown = disableNS;
} else {
  document.onmouseup = disableNS;
  document.oncontextmenu = disableIE;
}

document.oncontextmenu = new Function("return false");

export default {
  name: "App",

  data: () => ({
    drawer: true,
    items: [],

    menuCapacitado: [],
    mini: false,
    avatar: "",
  }),

  methods:{
    ...mapActions('UsuarioCapa',['guardarInfo','salirLogin']),

    salir () {
      this.$router.push({name: 'Login'})
      this.salirLogin()
    }
  },

  created() {
    this.avatar ="https://ui-avatars.com/api/?background=1976d2&color=fff&rounded=true&format=svg&name=";
  },

  computed: {
    ...mapGetters("UsuarioCapa", ["getUsuarioCapa", "getLogeadoCapa"]),

    menu(){
      // ADMIN
      if( this.getUsuarioCapa.idniveles_capacitacion == 1 ){
        return [
          { title: "Cursos"               , icon: "mdi-school"                , path: "Cursos"},
          { title: "Asignación de cursos" , icon: "mdi-google-classroom"      , path: "AsignacionCursos"},
          { title: "Mis Cursos "          , icon: "mdi-book-education-outline", path: "MisCursos"},
          { title: "Calificaciones"       , icon: "mdi-star"                  , path: "Calificaciones"},
          { title: "Mensajes Capacitador" , icon: "mdi-message"               , path: "MensajesCapacitador"},
          { title: "Mensajes Usuario"     , icon: "mdi-message"               , path: "MensajesUsuario"},
          { title: "Comentarios"          , icon: "mdi-comment"               , path: "Comentarios"},
          { title: "Inscritos"            , icon: "mdi-account"               , path: "Inscritos"},
          { title: "Videos Capacitación"  , icon: "mdi-animation-play"        , path: "VideosCapa"},
          { title: "Mi Capacitación"      , icon: "mdi-book-education-outline", path: "MiCapacitacion"},
        ]
      }

      if( this.getUsuarioCapa.idniveles_capacitacion == 2 ){
        return [
          { title: "Cursos"               , icon: "mdi-school"                , path: "Cursos"},
          { title: "Asignación de cursos" , icon: "mdi-google-classroom"      , path: "AsignacionCursos"},
          { title: "Mis Cursos "          , icon: "mdi-book-education-outline", path: "MisCursos"},
          { title: "Calificaciones"       , icon: "mdi-star"                  , path: "Calificaciones"},
          { title: "Mensajes Capacitador" , icon: "mdi-message"               , path: "MensajesCapacitador"},
          { title: "Mensajes Usuario"     , icon: "mdi-message"               , path: "MensajesUsuario"},
          { title: "Inscritos"            , icon: "mdi-account"               , path: "Inscritos"},
          { title: "Mi Capacitación"      , icon: "mdi-book-education-outline", path: "MiCapacitacion"},
        ]
      }

      if( this.getUsuarioCapa.idniveles_capacitacion == 3 ){
        return [
          { title: "Mis Cursos "      , icon: "mdi-book-education-outline" , path: "MisCursos" },
          { title: "Mensajes Usuario ", icon: "mdi-message"                , path: "MensajesUsuario" },
          { title: "Mi Capacitación"  , icon: "mdi-book-education-outline" , path: "MiCapacitacion"},
        ]
      }
    }
  },
};
</script>
<style>

  .btn-login{
    background: rgb(0,26,252);
    background: linear-gradient(90deg, rgba(0,26,252,1) 0%, rgba(0,255,252,1) 100%);
  }

  main{
    background: #F4F2F2 ;
  }

  .shadowCard{
    border-radius: 8px !important; 
    box-shadow: 0 20px 27px 0 rgba(0,0,0,0.2)  !important;
  }

  .v-application--is-ltr .v-list-item__icon:first-child{
    margin-right: 8px !important;
  }

  .v-application--is-ltr .v-list-item__action:last-of-type:not(:only-child), .v-application--is-ltr .v-list-item__avatar:last-of-type:not(:only-child), .v-application--is-ltr .v-list-item__icon:last-of-type:not(:only-child) {
    margin-left: 6px !important;
  }

  

  .v-dialog.v-dialog--active.v-dialog--fullscreen::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }

  .v-data-table--fixed-header > .v-dialog.v-dialog--active.v-dialog--fullscreen::-webkit-scrollbar:vertical {
    width: 2px !important;
  }

  .v-dialog.v-dialog--active.v-dialog--fullscreen::-webkit-scrollbar:vertical{
    width: 2px !important;
  }

  .v-dialog.v-dialog--active.v-dialog--fullscreen::-webkit-scrollbar-button:increment,.v-dialog.v-dialog--active.v-dialog--fullscreen::-webkit-scrollbar-button{
    display: none;
  }

  .v-dialog.v-dialog--active.v-dialog--fullscreen::-webkit-scrollbar:horizontal{
    height: 2px !important;
  }

  .v-dialog.v-dialog--active.v-dialog--fullscreen::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
  }


  .v-list-group .v-list-group__header .v-list-item__icon.v-list-group__header__append-icon {
    align-self: center;
    margin: 0;
    min-width: 0px !important;
    justify-content: flex-end;
  }

  .v-application--is-ltr .v-list-group--no-action.v-list-group--sub-group > .v-list-group__items > .v-list-item {
    padding-left: 40px !important;
  }

  .v-application--is-ltr .v-list--dense.v-list--nav .v-list-group--no-action > .v-list-group__items > .v-list-item {
    padding-left: 20px !important;
  }

</style>

