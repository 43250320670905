import Vue from 'vue'
import VueRouter from 'vue-router'

import Home             from '@/views/Home.vue'
import Cursos           from '@/views/catalogos/Cursos.vue'
import AsignacionCursos from '@/views/catalogos/AsignacionCursos.vue'

import Temas             from '@/views/catalogos/Temas.vue'
import Subtemas          from '@/views/catalogos/Subtemas.vue'
import Materiales        from '@/views/catalogos/Materiales.vue'
import Evaluaciones      from '@/views/catalogos/Evaluaciones.vue'
import PreviewEvaluacion from '@/views/catalogos/PreviewEvaluacion.vue'
import Preguntas         from '@/views/catalogos/Preguntas.vue'
import PreguntaMultiple  from '@/views/catalogos/PreguntaMultiple.vue'

import Calificaciones        from '@/views/catalogos/Calificaciones.vue'
import Notas                 from '@/views/catalogos/Notas.vue'
import Notificaciones        from '@/views/catalogos/Notificaciones.vue'
import NotificacionesUsuario from '@/views/catalogos/NotificacionesUsuario.vue'
import TipoEvaluaciones      from '@/views/catalogos/TipoEvaluaciones.vue'
import TipoMateriales        from '@/views/catalogos/TipoMateriales.vue'
import TipoPreguntas         from '@/views/catalogos/TipoPreguntas.vue'
import RespuestaEmpleados    from '@/views/catalogos/RespuestaEmpleados.vue'
import SubtemaEmpleados      from '@/views/catalogos/SubtemaEmpleados.vue'
import Insignias             from '@/views/catalogos/Insignias.vue'
import Recursos              from '@/views/catalogos/Recursos.vue'
import Preview               from '@/views/catalogos/PreviewCursos.vue'
import AudioPlayer           from '@/views/player/AudioPlayer.vue'

import MisCursos  from '@/views/catalogos/empleado/MisCursos.vue'
import Curso      from '@/views/catalogos/empleado/Curso.vue'
import Evaluacion from '@/views/catalogos/empleado/Evaluacion.vue'

import HistorialEmpleado from '@/views/catalogos/HistorialEmpleados.vue'

import Login from '@/views/usuario/Login.vue'
import store from '@/store'

// Mensajes
import MensajesCapacitador from '@/views/usuario/mensajes/MensajesCapacitador.vue'
import MensajesUsuario     from '@/views/usuario/mensajes/MensajesUsuario.vue'
import Comentarios         from '@/views/usuario/mensajes/Comentarios.vue'

import Inscritos           from '@/views/usuario/Inscritos.vue'

import VideosCapa          from '@/views/catalogos/VideosCapa.vue'
import MiCapacitacion      from '@/views/catalogos/empleado/MiCapacitacion.vue'


Vue.use(VueRouter)

const router = new VueRouter({
  mode: '',
  base: process.env.BASE_URL,
  routes: [
    { path: '/login/:id', name: 'Login', component: Login ,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true, libre: true }},

    { path: '/', name: 'Home', component: Home,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},

    { path: '/calificaciones', name: 'Calificaciones', component: Calificaciones,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},

    { path: '/cursos', name: 'Cursos', component: Cursos,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},

    { path: '/asignacion_cursos', name: 'AsignacionCursos', component: AsignacionCursos,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},

    { path: '/evaluaciones', name: 'Evaluaciones', component: Evaluaciones,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},

    { path: '/materiales', name: 'Materiales', component: Materiales,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},

    { path: '/notas', name: 'Notas', component: Notas,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},

    { path: '/notificaciones', name: 'Notificaciones', component: Notificaciones,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},

    { path: '/temas', name: 'Temas', component: Temas,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},

    { path: '/subtemas', name: 'Subtemas', component: Subtemas,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},

    { path: '/preguntas', name: 'Preguntas', component: Preguntas,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},

    { path: '/preguntas_multiples', name: 'PreguntaMultiple', component: PreguntaMultiple,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},

    { path: '/tipo_evaluaciones', name: 'TipoEvaluaciones', component: TipoEvaluaciones,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},

    { path: '/tipo_materiales', name: 'TipoMateriales', component: TipoMateriales,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},

    { path: '/tipo_preguntas', name: 'TipoPreguntas', component: TipoPreguntas,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},

    { path: '/respuesta_empleados', name: 'RespuestaEmpleados', component: RespuestaEmpleados,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},

    { path: '/subtema_empleados', name: 'SubtemaEmpleados', component: SubtemaEmpleados,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},

    { path: '/insignias', name: 'Insignias', component: Insignias,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},

    { path: '/recursos', name: 'Recursos', component: Recursos,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},

    { path: '/preview', name: 'Preview', component: Preview,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},

    { path: '/preview_eva', name: 'PreviewEvaluacion', component: PreviewEvaluacion,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},

    { path: '/player_audio', name: 'AudioPlayer', component: AudioPlayer,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},

    { path: '/mis_cursos', name: 'MisCursos', component: MisCursos,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},

    { path: '/curso', name: 'Curso', component: Curso,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},

    { path: '/evaluacion', name: 'Evaluacion', component: Evaluacion,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},

    { path: '/historial', name: 'HistorialEmpleado', component: HistorialEmpleado,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},

    // Mensajes
    { path: '/mensajescapacitador'  , name: 'MensajesCapacitador' , component: MensajesCapacitador,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    { path: '/mensajesusuario'      , name: 'MensajesUsuario'     , component: MensajesUsuario,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    { path: '/comentarios'          , name: 'Comentarios'         , component: Comentarios,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},

    { path: '/inscritos'          , name: 'Inscritos'         , component: Inscritos,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},


    { path: '/videoscapa', name: 'VideosCapa', component: VideosCapa,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},

    { path: '/micapacitacion', name: 'MiCapacitacion', component: MiCapacitacion,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},


  ]
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.libre)) {
    next()
  } else if (store.state.UsuarioCapa.datosUsuarioCapa) {
    if (to.matched.some(record => record.meta.USUARIO)) {
      next()
    }
  } else {
    next({
      name: 'Login'
    })
  }
})

export default router