<template>
	<v-dialog
    v-model="paramsDialogMinuta.estatus"
    hide-overlay
    persistent
    max-width="1000"
  >
    <v-card>
      <v-card-title primary-title >
        Ver minuta
      </v-card-title>
      <v-card-text >

      	<v-col cols="12" sm="6" md="6" lg="6" xl="6" class="pb-0">
          <v-text-field
            v-model="search"
            dense
            rounded
            filled
            single-line
            label="Buscar"
            append-icon="mdi-magnify"
          ></v-text-field>  
        </v-col>

        <v-data-table
          :headers="headerColaboradores"
          :items="minuta"
          class="elevation-0"
          dense
          :search="search"
        >
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-btn 
        	color="black" 
        	tile 
        	dark 
        	@click="cerrar()"
        	small
        >
        	<v-icon left small>mdi-cancel</v-icon>
      		Cancelar
      	</v-btn>
        <v-spacer></v-spacer>
        <v-btn 
        	color="green" 
        	tile 
        	dark 
        	@click="saveVideoUsuario()"
        	small
        >
        	<v-icon left small>mdi-content-save</v-icon>
      		Guardar
      	</v-btn>
      </v-card-actions>
      <v-btn 
        color="transparent elevation-0"
        x-small
        absolute
        top
        right
        fab
        class="mt-5 mr-0"
        @click="cerrar()"
       ><v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-dialog>
</template>
<script>
import axios from 'axios';

	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import { mapGetters, mapActions }  from 'vuex';
  import funcionesExcel        from '@/mixins/funcionesExcel'


	export default {

		components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

		props:[
			'paramsDialogMinuta',
			'curso'
	  ],

    computed: {
      ...mapGetters('login', ['getdatosUsuario', 'getLogeado', 'getEscuela','drawer2','getEstatusLlamada','getNuevosProspectos']),
    },

    data: () => ({

    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

			usuarios:[],
			puesto: null,
			puestos: [],

      headerColaboradores: [
        { text: 'ID'         , value: 'id_usuario'       },
        { text: 'Nombres'    , value: 'nombre_completo'  },  
        { text: 'Visto'      , value: 'visto'            },  
      ],
      search:'',

      recepcion: null,
			encargadas: null,
			coordinadoras: null,
			minuta:[],

			usuariosCapacitar:[],

    }),


    watch:{

    },

    created(){
    	this.initialize()
    },


    methods:{

      initialize () {  
        this.cargar = true
        this.minuta = []
        return this.$http.get('videos.minuta/' + this.curso.idvideos_capa ).then(response=>{
   
          this.minuta = response.data

          this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      cerrar(){
      	this.paramsDialogMinuta.estatus = false
      },


      exportar( ){

        this.exportExcel( this.prospectosCerrados  , 'prospectosCerrados')
      },


      validarSuccess( mensaje ){
	      this.cargar = false
	      this.parametros.mensaje      = mensaje
	      this.parametros.dialogError  = true 
	      this.parametros.color        = 'success'
	      this.respuestaAlerta         = true 
	    },

	    validarError ( value ) {
	      this.cargar = false 
	      if( value.status == 400 || value.status == 500){
	        this.parametros.mensaje      = value.body.message
	        this.parametros.dialogError  = true 
	        this.parametros.color        = '#FF2C2C'
	        this.respuestaAlerta = true 
	      }
	    },

	    validarErrorDirecto ( mensaje ) {
	      this.cargar = false 
	      this.parametros.mensaje      = mensaje
	      this.parametros.dialogError  = true 
	      this.parametros.color        = '#FF2C2C'
	      this.respuestaAlerta = true 
	    },
    }
	}
</script>
