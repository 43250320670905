<template>
  <v-container class="mt-4">
    <v-row justify="center" class="margen_abajo">
      <v-col cols="12" xl="8" class="pa-0">
        <v-card class="shadowCard">
          <v-card-text>
            <v-row justify="space-around">

              <!-- Vista previa del material a ver -->
              <v-col cols="12" md="8" lg="8" class="pa-0">

                <!-- Verificar que haya material -->
                <div v-if="materialItem.url != null" >

                  <!-- Si es video -->
                  <video id="video" 
                    :src="`${url2}${materialItem.url}`" 
                    frameborder="0" 
                    allowfullscreen
                    controls
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    style="width: 100%; height: 100%; background: #FFFFFF;" allowtransparency="true"
                    controlsList="nodownload"
                    v-if="materialItem.idtipo_material == 1">
                  </video>

                  <!-- Si es un audio -->
                  <v-card v-if="materialItem.idtipo_material == 2">
                    <v-progress-linear
                      class="mt-2"
                      height="5"
                      v-model="trackProgress"
                      @click="updateSeek($event)"
                    ></v-progress-linear>
                    <div class="d-flex flex-no-wrap justify-space-between">
                      <div>
                        <v-card-title
                          ><h3>
                            {{ materialItem.url.replace(".mp3", "") }}
                          </h3>
                        </v-card-title>
                        <v-container>
                          <h5 dense>
                            {{ getTrackInfo.seek | minutes }}/{{
                              getTrackInfo.duration | minutes
                            }}
                          </h5>

                          <v-card-actions>
                            <v-btn
                              v-if="play == false"
                              outlined
                              fab
                              small
                              color="light-blue"
                              @click="playTrack()"
                            >
                              <v-icon large>mdi-play</v-icon>
                            </v-btn>
                            <v-btn
                              v-else
                              outlined
                              fab
                              small
                              color="light-blue"
                              @click="pauseTrack()"
                            >
                              <v-icon large>mdi-pause</v-icon>
                            </v-btn>
                          </v-card-actions>
                        </v-container>
                      </div>

                      <v-avatar class="ma-3" size="125" tile>
                        <v-icon large>mdi-headphones</v-icon>
                      </v-avatar>
                    </div>
                  </v-card>

                  <!-- Si es una iamgen -->
                  <v-img
                    v-if="materialItem.idtipo_material == 3"
                    contain
                    :src="url + materialItem.url"
                    class="white--text align-end"
                    height="600"
                    width="100%"
                  >
                  </v-img>

                  <!-- Si es un documento de pdf -->
                  <embed
                    type="application/pdf"
                    v-if="materialItem.idtipo_material == 4"
                    :src="url3 + materialItem.url + '#toolbar=0'"
                    height="600"
                    width="100%"
                  />

                  <!-- Presentación Power Point -->
                    <!-- :src="`https://view.officeapps.live.com/op/embed.aspx?wdPrint=0&zoom=0&src=${ url3 + materialItem.url }`" -->

                  <v-card class="pa-1" v-if="materialItem.idtipo_material == 6">
                    <iframe 
                      v-if="materialItem.idtipo_material == 6"
                      :src="`https://view.officeapps.live.com/op/embed.aspx?wdPrint=0&zoom=0&src=${ url3 + materialItem.url }`"
                      width="100%" 
                      height="400"
                      frameborder='0'
                      sandbox="allow-forms allow-modals allow-orientation-lock allow-popups allow-same-origin allow-scripts" 
                      scrolling="no" 
                      style="border: none; max-width: 100%; max-height: 100vh" 
                      allowfullscreen 
                      mozallowfullscreen 
                      msallowfullscreen 
                      webkitallowfullscreen
                    ></iframe>


                    <div class="bloqueador">
                    </div>

                    <v-btn 
                      color="primary"
                      absolute
                      bottom
                      right
                      @click="zoomPresentacion(`https://view.officeapps.live.com/op/embed.aspx?wdPrint=0&zoom=0&src=${ url3 + materialItem.url }`)"
                    >
                      Zoom
                    </v-btn>

                  </v-card>
                </div>

                <!-- Mostrar portada en caso de no haber material -->
                <v-card  v-else flat width="100%" style="border-radius: 0px;">
                  <v-img
                    contain
                    :src="url + getCurso.imagen"
                    class="white--text align-end"
                    max-width="100%"
                    aspect-ratio="1.4"
                  >
                    <v-card-title class="primary pa-2 pl-6" dense>{{ getCurso.curso }}</v-card-title>
                  </v-img>
                </v-card>

                <!-- Si no hay material, poner la descripción del curso -->
                <v-card class="elevation-0" v-if="!materialItem.url">
                  <v-card-title >
                    <h2>{{ getCurso.curso }}</h2>
                  </v-card-title>
                  <v-card-text>
                    {{ getCurso.descripcion }} 
                    <v-row>
                      <v-col>
                        <v-card-text><strong>Duración:</strong></v-card-text>
                        <v-card-text class="my-n8 mx-auto">
                          <v-icon>
                            mdi-clock-time-two-outline
                          </v-icon>
                          <span class=" font-weight-light">{{ getCurso.duracion + " horas" }}</span>
                        </v-card-text>
                      </v-col>
                      <v-col cols="20" sm="12" md="4">
                        <v-card-text > <strong>Capacitador: </strong></v-card-text>
                        <v-card-text class="mt-n8 mx-auto">
                          <v-avatar class="mr-2" size="30px">
                            <img
                              :src='`https://ui-avatars.com/api/?background=1976d2&color=fff&rounded=true&format=svg&name=${getCurso.capacitador}`'
                              alt="John"
                            />
                          </v-avatar>
                          <span class=" font-weight-light"
                            >{{ getCurso.capacitador }}
                          </span>
                        </v-card-text>
                      </v-col>
                    </v-row>
                 </v-card-text>
                </v-card>

                <!-- Si ya hay material, hay que traer los comentarios de su curso -->
                <div v-else class="px-2">
                  <div class="text-h6 mb-2">comentarios | {{ comentarios.length }} |</div>

                  <v-row>
                    <v-col v-if="comentarios.length > 0" cols="12" class="mb-4">
                      <div 
                        v-for="(com, i) in comentarios"
                        :key="i"
                      >
                        <v-card 
                          class="mr-6 mt-2 pa-3" 
                          dark 
                          color="#49ABF3" 
                          shaped 
                          style="border-radius: 15px; border-top-left-radius: 0;"
                        >
                          <pre class="text-subtitle-1">{{ com.nota }}</pre>
                        </v-card>
                      </div>
                    </v-col>
                  </v-row>

                  <v-textarea
                    outlined
                    label="Escribir un comentario"
                    v-model="mensaje"
                    hide-details
                    dense
                    :append-icon="mensaje ? 'mdi-send' : ''"
                    @click:append="agregarComentario"
                  ></v-textarea>

                </div>
              </v-col>

              <!-- Desglose de temas del curso -->
              <v-col cols="12" md="4" lg="4" class="pa-0">

                <v-card class="elevation-0">
                  <div class="pa-2 pt-4">
                    <v-progress-linear
                      height="20"
                      :value="getCurso.avance"
                      dark
                      color="green"
                      style="border-radius: 25px;"
                      rounded
                    >
                      <strong class="text-caption">{{ getCurso.avance }}% de avance</strong>
                    </v-progress-linear>
                  </div>

                  <v-toolbar class="elevation-0 text-h6">
                    <span v-if="!buscarActive">Contenido del curso</span>
                    <v-text-field
                      v-if="buscarActive"
                      label="Buscar"
                      dense
                      v-model="search"
                      hide-details
                      outlined
                      class="pr-2"
                      :autofocus="buscarActive"
                    ></v-text-field>

                    <v-spacer v-if="!buscarActive"></v-spacer>
                    <!-- Activar el buscador -->
                    <v-btn 
                      icon 
                      @click="buscarActive = true" 
                      v-if="!buscarActive"
                    >
                      <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                    <!-- DEscativar el buscador -->
                    <v-btn 
                      icon 
                      @click="buscarActive = false, search = '', initialize()" 
                      v-else
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar>

                  <!-- Material del curso -->
                  <v-list nav dense class="mr-0">
                    <v-list-group
                      v-for="item in filterMaterial"
                      :key="item.idtemas"
                      v-model="item.active"
                      prepend-icon="mdi mdi-circle-medium theme--light"
                      no-action
                    >
                      <template v-slot:activator>
                        <v-list-item-content> 
                          <v-list-item-title v-text="item.tema"></v-list-item-title>
                        </v-list-item-content>
                      </template>

                      <v-list dense nav v-if="item.subtemas2">
                        <v-list-group
                          v-for="sub in item.subtemas2"
                          :key="sub.idsubtema"
                          v-model="sub.active"
                          :prepend-icon="sub.action"
                          no-action
                        >
                          <template v-slot:activator>
                            <v-list-item-content>
                              <v-list-item-title v-text="sub.subtema"></v-list-item-title>
                            </v-list-item-content>
                          </template>

                          <v-list-item
                            v-for="mat in sub.materiales"
                            :key="mat.idmaterial"
                            @click="materialShow(mat)"
                          >

                            <v-list-item-icon dense>
                              <v-icon dense v-if="mat.extension == 'mp4'" color="red">mdi-play-box</v-icon>
                              <v-icon dense v-if="['mp3','wav'].includes(mat.extension)" color="black">mdi-microphone</v-icon>
                              <v-icon dense v-if="mat.extension == 'pdf'" color="red">mdi-file-pdf-box</v-icon>
                              <v-icon dense v-if="['jpg','webp','gif','png','jpeg'].includes(mat.extension)" color="primary">mdi-camera</v-icon>
                              <v-icon dense v-if="['ppt', 'pptx'].includes(mat.extension)" color="red">mdi-microsoft-powerpoint</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                              <v-list-item-title v-text="mat.nombre"></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item
                            v-for="evaluacion in sub.evaluaciones"
                            :key="evaluacion.idevaluacion"
                            link
                            dense
                            @click="evaluacionShow( evaluacion )"
                          >

                            <v-list-item-icon dense>
                              <v-icon dense color="green">mdi-pencil-ruler</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title
                              dense
                              v-text="evaluacion.evaluacion"
                            ></v-list-item-title>
                          </v-list-item>

                        </v-list-group>
                      </v-list>
                    </v-list-group>
                  </v-list>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-btn
        fab
        dark
        bottom
        right
        fixed
        color="green"
        v-if="materialItem.url"
        @click="dialogMensajes = true"
      >
        <v-icon color="white">mdi-message-bulleted</v-icon>
      </v-btn>
    </v-row>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

    <v-dialog
      v-model="dialogMensajes"
      persistent
      max-width="600"
    >
      <v-card>
        <v-card-title class="text-subtitle-1">
          Deja tu mensaje a: {{ getCurso.capacitador }}
        </v-card-title>
        <v-card-text>
          <v-textarea
            v-model="mensajeCapacitador"
            label="Mensaje"
            filled
            hide-details
            dense
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="red"
            dark
            small
            rounded
            @click="dialogMensajes = false, mensajeCapacitador = ''"
          >
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            dark
            small
            rounded
            @click="enviarMensaje()"
          >
            Enviar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog
      v-model="dialogZoom"
      persistent
      max-width="1000"
    >
      <v-card>
        <v-card-text>
          <v-card class="pa-1">
            <iframe 
              :src="`https://view.officeapps.live.com/op/embed.aspx?wdPrint=0&zoom=0&src=https://web-back-inbi.club/kpi/capacitacion.archivos/ee962690-f93f-4fb6-b1f1-4819bf2f232d.pptx`"
              width="100%" 
              height="600"
              frameborder='0'
              sandbox="allow-forms allow-modals allow-orientation-lock allow-popups allow-same-origin allow-scripts" 
              scrolling="no" 
              style="border: none; max-width: 100%; max-height: 100vh" 
              allowfullscreen 
              mozallowfullscreen 
              msallowfullscreen 
              webkitallowfullscreen
            ></iframe>

            <div class="bloqueador">
            </div>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="red"
            dark
            small
            rounded
            @click="dialogZoom = false"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </v-container>
</template>

<script>
  import * as easings from 'vuetify/lib/services/goto/easing-patterns'
  import AudioPlayer from "@/views/player/AudioPlayer.vue";

  // Componentes
  import Alerta                 from '@/components/alertas/Alerta.vue'
  import carga                  from '@/components/alertas/carga.vue';
  import dialogMensajeMaterial  from '@/components/mensajes/dialogMensajeMaterial.vue';


import { mapActions, mapGetters } from "vuex";

export default {
  components:{
    Alerta,
    carga,
    AudioPlayer,
    dialogMensajeMaterial
  },

  data: () => ({
    // Alertas
    parametros:{
      dialogError: false,
      mensaje: '',
      color: ''
    },

    respuestaAlerta:false,
    loader: true,
    cargar: false,

    dialogMensajes:false,

    buscarActive:false,
    search:'', 
    materialCompleto: [],

    url: "",
    url2: "",
    url4:'',
    ///usuarios
    usuarios: [],
    tema: null,
    temas: [],
    materialItem: {},
    
    //**---audio---**//
    play: false,
    url3: "",
    playing: false,
    seek: 0,
    progress: 0,
    duration: 0,

    mensaje:'',
    comentarios:[],

    mensajeCapacitador: '',

    dialogZoom: false,
    presentacion:'',

  }),

  computed: {
    ...mapGetters( 'UsuarioCapa', ['getUsuarioCapa']),

    filterMaterial ( ){
      // Llenamos los temas con el material completo
      let temas = this.materialCompleto
      if( this.search ){
        // Filtramos
        return temas.filter( el => { 
          // Verificamos si dentro de los subtemas tenemos lo que estamos buscando 
          if( el.subtemas.filter( el => { return el.subtema.toUpperCase().match(this.search.toUpperCase() )  }).length > 0 ){
            // Filtramos los subtemas dentro del temas
            el.subtemas2 = el.subtemas.filter( el => { return el.subtema.toUpperCase().match(this.search.toUpperCase() )  })
            // Y ahora si retornamos el tema
            return el.tema
          }else{
            el.subtemas2 = el.subtemas
            // En caso de no estar en los subtemas lo que buscamos, lo buscamos en los temas
            return el.tema.toUpperCase().match(this.search.toUpperCase() )
          }

        })

      }else{
        for( const i in this.materialCompleto ){
          this.materialCompleto[i]['subtemas2'] = this.materialCompleto[i].subtemas
        }
        return this.materialCompleto 
      }
    },

    height () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 270
        case 'sm': return 270
        case 'md': return 600
        case 'lg': return 600
        case 'xl': return 600
      }
    },

    hVideo () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 0
        case 'sm': return 0
        case 'md': return 600
        case 'lg': return 600
        case 'xl': return 600
      }
    },

    formTitle() {
      return this.editedIndex === -1 ? "Nuevo curso" : "Editar curso";
    },

    ...mapGetters("Cursos", ["getCurso"]),
    ...mapGetters("Materiales", ["getMaterial"]),

    //**---audio---**//
    trackProgress() {
      this.progress = this.seek / this.audio.duration();
      return this.progress * 100;
    },

    getTrackInfo() {
      let seek = this.seek,
        duration = this.audio.duration();
      return {
        seek,
        duration,
      };
    },
  },

  watch: {

    getCurso() {
      this.initialize();
    },

    materialItem( value ) {
      // Audio
      if( value.idtipo_material == 2 ){
        //**---audio---**//
        const rutaAudio = this.url4 + this.getMaterial.url
        this.audio = new Howl({
          src: [rutaAudio],
          onend: () => {
            this.stopTrack();
            this.play = false;
          },
        });
      }
    },

    //**---audio---**//
    playing(playing) {
      this.seek = this.audio.seek();
      let updateSeek;
      if (playing) {
        updateSeek = setInterval(() => {
          this.seek = this.audio.seek();
        }, 250);
      } else {
        clearInterval(updateSeek);
      }
    },

    audio() {},
  },

  created() {

    this.initialize();

    this.url  = "https://academicokpi.club/" + "capacitacion-imagenes/";
    this.url2 = "https://academicokpi.club/" + "capacitacion-videos/";
    this.url3 = "https://academicokpi.club/" + "capacitacion-archivos/";
    this.url4 = "https://academicokpi.club/" + "capacitacion-audios/";

  },

  methods: {
    initialize() {
      this.cargar       = true
      this.materialItem = {};
      this.play = false;
      this.$http.get("temas.cursos.all/" + this.getCurso.idcurso).then((responsetemas) => {
        this.temas            = responsetemas.body;
        this.materialCompleto = responsetemas.body;
        for( const i in this.materialCompleto ){
          this.materialCompleto[i]['subtemas2'] = this.materialCompleto[i].subtemas
        }
        this.cargar = false
      }).catch( error =>{
        this.validarError( error )
      }).finally( () => { this.cargar = false })
    },

    ...mapActions("Materiales", ["guardarMaterial"]),
    ...mapActions("Evaluaciones", ["guardarIdEvaluacion", "guardarEvaluacion"]),

    // Ver el material activo
    async materialShow(material) {
      // Audio
      if( material.idtipo_material == 2){
        this.play = false;
        this.stopTrack();
      }

      this.materialItem = material;
      this.guardarMaterial(material);

      // consultar las notas de ese material de ese usuario
      await this.getComentarios()

    },

    getComentarios( ){
      const payload = {
        idmaterial: this.materialItem.idmaterial,
        iderp:      this.getUsuarioCapa.iderp
      }

      this.comentarios = []
      this.cargar      = true
      this.$http.post("materiales.comentarios", payload ).then((response) => {
        this.comentarios   = response.body;
        this.cargar        = false
      }).catch( error =>{
        this.validarError( error )
      }).finally( () => { this.cargar = false })
    },

    scroll(){
      window.scrollTo(0,400)
    },

    evaluacionShow(item) {
      this.guardarEvaluacion(item);
      this.$router.push({ name: "Evaluacion" });
    },

    goBack() {
      this.play = false;
      this.stopTrack();
      window.history.back();
    },

    //**---audio---**//
    playTrack() {
      this.audio.play();
      this.play = true;
      this.playing = true;
    },

    pauseTrack() {
      this.play = false;
      this.audio.pause();
      this.playing = false;
    },
    stopTrack() {

      if( this.audio ){
        this.audio.stop();
      }
      this.playing = false;
    },

    //linear progress bar
    updateSeek(event) {
      if (this.audio.playing()) {
        this.audio.seek((this.audio.duration() / 100) * percents);
      }

      let el = document.querySelector(".progress-linear__bar"),
        mousePos = event.offsetX,
        elWidth = el.clientWidth,
        percents = (mousePos / elWidth) * 100;
    },

    validarSuccess( mensaje ){
      this.cargar = false
      this.parametros.mensaje      = mensaje
      this.parametros.dialogError  = true 
      this.parametros.color        = 'success'
      this.respuestaAlerta         = true 
    },

    validarError ( value ) {
      this.cargar = false 
      if( value.status == 400 || value.status == 500){
        this.parametros.mensaje      = value.body.message
        this.parametros.dialogError  = true 
        this.parametros.color        = '#FF2C2C'
        this.respuestaAlerta = true 
      }
    },

    validarErrorDirecto ( mensaje ) {
      this.cargar = false 
      this.parametros.mensaje      = mensaje
      this.parametros.dialogError  = true 
      this.parametros.color        = '#FF2C2C'
      this.respuestaAlerta = true 
    },

    agregarComentario( ){
      const payload = {
        idmaterial: this.materialItem.idmaterial,
        iderp:      this.getUsuarioCapa.iderp,
        nota:       this.mensaje
      }

      this.cargar      = true
      this.$http.post("materiales.comentarios.add", payload ).then((response) => {
        this.mensaje = ''
        this.getComentarios( )
        this.cargar        = false
      }).catch( error =>{
        this.validarError( error )
      }).finally( () => { this.cargar = false })
    },

    enviarMensaje ( ){
      this.cargar      = true
      this.materialItem['idusuarioerp'] = this.getUsuarioCapa.iderp
      this.materialItem['mensaje']      = this.mensajeCapacitador
      this.materialItem['emisor']       = this.getUsuarioCapa.iderp
      this.$http.post("capacitador.mensaje.add", this.materialItem ).then((response) => {
        this.mensajeCapacitador = ''
        this.dialogMensajes     = false
        this.cargar             = false
        this.validarSuccess( response.body.message )
      }).catch( error =>{
        this.validarError( error )
      }).finally( () => { this.cargar = false })
    },

    zoomPresentacion( value ){
      this.presentacion = value
      this.dialogZoom    = true
    }
  },
};
</script>
<style scoped>

  #eventos::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }

  #eventos::-webkit-scrollbar:vertical {
    width: 6px !important;
  }

  #eventos::-webkit-scrollbar:vertical{
    width: 6px !important;
  }

  #eventos::-webkit-scrollbar-button:increment,.v-data-table__wrapper::-webkit-scrollbar-button{
    display: none;
  }

  #eventos::-webkit-scrollbar:horizontal{
    height: 6px !important;
  }

  #eventos::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
  }

  .margen_abajo{
    margin-bottom: 50px;
  }


  #application.cui-statusbar .cui-ctl {
    padding: 50px 9px 2px !important;
  }

  #SwitchToReadingMode-Small14{
    padding: 50px 9px 2px !important;
  }

  .bloqueador{
    position: absolute;
    bottom: 10px;
    right: 0;
    height: 20px;
    font-size: 30px;
    color: transparent;
    width: 30%;
    height: 30px;
  }

</style>
