<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title primary-title>
            <span class="text-subtitle-1">Videos de capacitación</span>     
            <v-spacer></v-spacer>
            <v-btn color="primary" dark class="mb-2" small @click="dialog = true">
              Nuevo video
            </v-btn>
          </v-card-title>


          <v-card-text>
            <v-row justify="end">
              <v-col cols="12" md="6" lg="4">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Buscar"
                  hide-details
                  filled
                  dense
                 ></v-text-field>
              </v-col>
            </v-row>

            <v-data-table
              dense
              :headers="headers"
              :items="cursos"
              :search="search"
              :sort-desc="[false, true]"
              multi-sort
              class="elevation-0"
            >
              <template v-slot:item.actions="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      icon
                      @click="openViewItem(item)"
                    >
                      <v-icon small>
                        mdi-plus-thick
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Agregar personal</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      icon
                      @click="openMinuta(item)"
                    >
                      <v-icon small>
                        mdi-eye
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Ver minuta</span>
                </v-tooltip>


                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      icon
                      @click="editItem(item)"
                    >
                      <v-icon small>
                        mdi-pencil
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Editar curso</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      icon
                      @click="deleteItem(item)"
                    >
                      <v-icon small>
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Eliminar curso</span>
                </v-tooltip>
              </template>

              <template v-slot:no-data>
                <v-btn color="primary" @click="initialize">
                  Actualizar
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title class="justify-center">
          <span class="text-xs-center">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form ref="form" lazy-validation>
              <v-row>
                <v-col cols="12" sm="8" md="12">
                  <v-text-field
                    v-model="editedItem.titulo"
                    label="Titulo"
                    filled
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="8" md="12">
                  <v-textarea
                    :rules="rules"
                    v-model="editedItem.descripcion"
                    label="Descripción"
                    value=""
                    rows="2"
                    hide-details
                    filled
                  ></v-textarea>
                </v-col>

                <v-col cols="12" sm="8" md="12">
                  <v-file-input
									  @change="cargarVideo()"
                    v-model="files"
									  accept="video/*"
									  label="Video"
									  prepend-inner-icon="mdi-video"
									  hide-details
									  dense
									  filled
									></v-file-input>
                </v-col>

                <v-col cols="12">
                	<!-- crearss  -->
                  <div v-if="files">
                    <video
                      height="300"
                      width="100%"
                      controls
                      controlslist="nodownload"
                    >
                      <source :src="archivo" type="video/mp4" />
                    </video>
                  </div>

                  <!-- crearss  -->
                  <div v-if="editedItem.video && editedIndex > -1">
                    <video
                      height="300"
                      width="100%"
                      controls
                      controlslist="nodownload"
                    >
                      <source :src="url + editedItem.video" type="video/mp4" />
                    </video>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn color="blue darken-1" text @click="reset">
            Limpiar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">
            Cancelar
          </v-btn>
          <v-btn color="blue darken-1" text @click="save">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">
          ¿Estás seguro de eliminar este elemento?</v-card-title
        >
        <v-card-actions>
          <v-btn 
            color="error" 
            dark 
            @click="closeDelete"
            class="text-capitalize"
          >
            No, Cancelar
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn 
            color="primary" 
            dark 
            @click="deleteItemConfirm"
            class="text-capitalize"
          >
            Sí, eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogPreview"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar class="elevation-0" dark color="primary">
          <v-btn icon dark @click="closePreview()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Vista previa</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <Preview />
      </v-card>
    </v-dialog>

    <AgregarPersonal
      v-if="paramsDialog.estatus"
      :paramsDialog="paramsDialog"
      :curso="curso"
    />

    <MinutaVideo
      v-if="paramsDialogMinuta.estatus"
      :paramsDialogMinuta="paramsDialogMinuta"
      :curso="curso"
    />

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>


  </v-container>
</template>

<script>
var moment = require("moment");
moment.locale();

import { mapActions, mapGetters } from "vuex";
import Preview from "./PreviewCursos.vue";
import { Howl, Howler } from "howler";
// Componentes
import Alerta                from '@/components/alertas/Alerta.vue'
import carga                 from '@/components/alertas/carga.vue';

import AgregarPersonal       from '@/components/cursos/AgregarPersonal.vue';
import MinutaVideo           from '@/components/cursos/MinutaVideo.vue';

export default {
  components: {
    Preview,
    Alerta,
		carga,
    AgregarPersonal,
    MinutaVideo
  },

  data: () => ({

  	// Alertas
    parametros:{
      dialogError: false,
      mensaje: '',
      color: ''
    },

    respuestaAlerta:false,
    loader: true,
    cargar: false,


    rules: [(value) => !!value || "Campo obligatorio."],
    //variables utilizadas para subir imgs
    fileimagen: [],
    formdata: "",
    foto:'',

    //variables utilizadas para subir imgs
    files: null,
    archivo: "",
    link: "",

    url: "",
    ///usuarios
    usuario: null,
    usuarios: [],

    curso: null,

    dialog: false,
    dialogDelete: false,
    dialogPreview: false,
    search:"",
    headers: [
      { text: "Titulo"     , value: "titulo" },
      { text: "Descripción", value: "descripcion" },
      { text: "Capacitador", value: "usuario_capacita" },
      { text: "Actions"    , value: "actions" },
    ],
    cursos: [], //arreglo
    editedIndex: -1,
    editedItem: {
      idvideos_capa: 0,
      video: "",
      capacitador: 0,
      titulo: '',
      descripcion: "",
      deleted: null,
      fecha_creacion: null
    },
    defaultItem: {
      idvideos_capa: 0,
      video: "",
      capacitador: 0,
      titulo: '',
      descripcion: "",
      deleted: null,
      fecha_creacion: null
    },

    paramsDialog:{
      estatus: false,
    },

    paramsDialogMinuta:{
      estatus: false,
    }
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Agregar un video" : "Editar video";
    },
    ...mapGetters("Cursos", ["getIdCurso"], ["getCurso"]), //cambios

    ...mapGetters("Materiales", ["getMaterial"]),
    ...mapGetters("UsuarioCapa", ["getUsuarioCapa", "getLogeadoCapa"]),
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogPreview(val) {
      val || this.closePreview();
    },
    fileimagen() {
      this.editedItem.imagen;
    },
    foto() {
      this.foto;
    },
  },

  async created() {
    await this.initialize();
    await this.getUsuarios( )
    await this.getPuestos()

    this.url = this.$http.options.root + "capacitacion.videos/";
  },

  methods: {

  	getUsuarios( ){

  		//usuarios.all.erp
  		this.usuarios = []

      return this.$http.get("usuarios.all").then((responseUsuarios) => {
        for (const i in responseUsuarios.body) {
          responseUsuarios.body[i].empleado = responseUsuarios.body[
            i
          ].empleado.toUpperCase();
        }
        this.usuarios = responseUsuarios.body;
          
      }).catch((error) => {
        console.log(error);
      });
  	},


    initialize() {
    	this.cursos = []
      //llamo a la api para obtener todos los registros
      return this.$http.get("videos.capa.all").then((response) => {
        this.cursos = response.body;
      }).catch((error) => console.log(error));
    },

    getPuestos( ){
      this.puestos = []
      return this.$http.get('puestos.all').then(response=>{
        this.cargar = false
        this.puestos = response.data
        // Traer los puestos
      }).catch( error =>{
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })
    },

    ...mapActions("Cursos", ["guardarIdCurso", "guardarCurso"]), ///cambios

    ...mapActions("Materiales", ["guardarMaterial"]),

    editItem(item) {
      this.usuario = item.idcapacitador;
      this.editedIndex = this.cursos.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    openViewItem(item) {
      this.curso = item
      this.paramsDialog.estatus = true
    },

    openMinuta(item) {
      this.curso = item
      this.paramsDialogMinuta.estatus = true
    },

    openPreviewItem(item) {
      this.dialogPreview = true;

      // this.$router.push({ name: "Preview" });
    },
    deleteItem(item) {
      this.editedIndex = this.cursos.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
    	this.editedItem.deleted = 1
      this.$http.put("videos.capa/" + this.editedItem.idvideos_capa, this.editedItem).then((response) => {
      	this.closeDelete();
        this.initialize();
      }).catch((error) =>{
      	this.validarError( error )
      });

    },

    close() {
      this.dialog = false;
      this.reset();
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closePreview() {
      this.dialogPreview = false;
      Howler.stop();
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      
      if (this.editedIndex > -1) {
        this.$http.put("videos.capa/" + this.editedItem.idvideos_capa, this.editedItem).then((response) => {
          this.initialize();
          this.close( )
        }).catch( error =>{
          this.validarError( error )
        }).finally( () => { this.cargar = false })
      } else {

        // Validar que todo este bien cargardo, datos y todo 
        if( !this.files ){
          this.validarErrorDirecto( 'Agrega un material por favor ')
          return
        }

        if( !this.editedItem.titulo || !this.editedItem.descripcion ){
          this.validarErrorDirecto( 'Llena todos los datos por favor ')
          return
        }

        this.cargar = true
        // Guardamos primero el material 
        const saveMaterial = await this.saveMaterial( this.files, this.editedItem.idtipo_material ).then( response=> response )

        // Agregamos los datos extra que necesitamos
        this.editedItem.video              = saveMaterial.nombre
        this.editedItem.capacitador        = this.getUsuarioCapa.iderp

        // Agregar un material
        this.$http.post("videos.capa.add", this.editedItem).then((response) => {
          this.cargar = false
          this.close();
          this.reset();
          this.initialize( )
        }).catch( error =>{
          this.validarError( error )
        }).finally( () => { this.cargar = false })

      //   this.materiales.push(this.editedItem);
      }
    },

    saveMaterial( archivo, tipo_material ){
      return new Promise((resolve,reject)=>{
        let formData = new FormData();
        //se crea el objeto y se le agrega como un apendice el archivo
        formData.append("file", archivo);
        this.$http.post("capacitacion.materiale.add/" + 1, formData).then( response => {
          resolve( response.body ) 
        }).catch( error =>{
          this.validarError( error )
        }).finally( () => { this.cargar = false })
      })
    },

    reset() {
      this.$refs.form.reset();
      // this.fileimagen = [];
      this.fileimagen = [];
      this.editedItem = {};
    },


    cargarVideo() {
      if ( this.files && this.files.name != undefined) {
        // creamos una variable tipo FormData
        let formData = new FormData();
        //se crea el objeto y se le agrega como un apendice el archivo
        formData.append("file", this.files);
        //mandamos a ocvertir la imagen a base64 pero mandamos el docuemnto, el formdata, el nombre
        this.getBase64(this.files, formData);
      }
    },

    getBase64(file, formData) {
      var me = this;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function() {
        me.archivo = reader.result; //base64
        me.formdata = formData; //archivo
      };
    },

    validarSuccess( mensaje ){
      this.cargar = false
      this.parametros.mensaje      = mensaje
      this.parametros.dialogError  = true 
      this.parametros.color        = 'success'
      this.respuestaAlerta         = true 
    },

    validarError ( value ) {
      this.cargar = false 
      if( value.status == 400 || value.status == 500){
        this.parametros.mensaje      = value.body.message
        this.parametros.dialogError  = true 
        this.parametros.color        = '#FF2C2C'
        this.respuestaAlerta = true 
      }
    },

    validarErrorDirecto ( mensaje ) {
      this.cargar = false 
      this.parametros.mensaje      = mensaje
      this.parametros.dialogError  = true 
      this.parametros.color        = '#FF2C2C'
      this.respuestaAlerta = true 
    },
  },
};
</script>
