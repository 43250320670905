<template>
  <v-container>
    <v-alert
      class="mx-auto mt-6"
      width="350"
      dense
      v-if="cursos.length === 0"
      text
      prominent    
      icon="mdi-alert-circle-outline"
    >
      Aún no tienes cursos asignados
    </v-alert>

    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="6"
        lg="4"
        xl="3"
        v-for="(curso, i) in cursos"
        :key="i"
      >
        <v-card>

          <!-- Si es video -->
          <video id="video" 
            :src="`${url2}${cursos.video}`" 
            frameborder="0" 
            allowfullscreen
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowtransparency="true"
            style="width: 100%; height: 100%; background: #FFFFFF;"
            controlsList="nodownload"
            poster="@/assets/sin_foto.jpg"
           >
          </video>

          <v-card-text class="text--primary">

          	<div>
              <strong>Titulo:</strong> {{ curso.titulo }}
            </div>

            <div>
              <strong>Descripción:</strong> {{ curso.descripcion }}
            </div>

            <div>
              <strong>Capacitador:</strong> {{ curso.nombre_capacitador }}
            </div>

            <div>
              <strong>Inició el: </strong> {{ curso.fecha_creacion }}
            </div>

          </v-card-text>

          <v-card-actions class="text-center mb-2">
            <v-chip
              color="red"
              dark
              rounded
              @click="openCurso(curso)"
            >
              Ver video
            </v-chip>

          </v-card-actions>

          <div class="pa-2">
            <v-progress-linear
              height="20"
              :value="curso.avance"
              dark
              color="green"
              style="border-radius: 25px;"
              rounded
            >
              <strong class="text-caption">{{ curso.avance }}% de avance</strong>
            </v-progress-linear>
          </div>

        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialogVideo"
      :overlay="false"
      max-width="1000px"
      transition="dialog-transition"
    >
      <v-card v-if="curso">
        <v-card-title primary-title>
          {{ curso.titulo }}
        </v-card-title>
        <v-card-text>
          <video id="video" 
            :src="`${url2}${curso.video}`" 
            frameborder="0" 
            allowfullscreen
            controls 
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowtransparency="true"
            style="width: 100%; height: 100%; background: #FFFFFF;"
            controlsList="nodownload"
           >
          </video>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
  var moment = require("moment");
  moment.locale();

  import { mapActions, mapGetters } from "vuex";

  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'

  export default {

    components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],


    data: () => ({

      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      cursos: [], //arreglo
      //evaluacion
      evaluaciones: [],

      url2:'',

      curso: null,
			dialogVideo: false,
    }),

    computed: {
      ...mapGetters("Cursos", ["getIdCurso"], ["getCurso"]), //cambios

      ...mapGetters("Materiales", ["getMaterial"]),

      ...mapGetters("UsuarioCapa", ["getUsuarioCapa", "getLogeadoCapa"]),
    },

    watch: {},

    created() {
    	this.url2 = "https://academicokpi.club/" + "capacitacion-videos/";
      this.initialize();
      this.url = "https://academicokpi.club/" + "capacitacion-imagenes/";
    },

    methods: {
      initialize() {
        this.cargar = true
        // console.log(this.getUsuarioCapa.idusuario);
        
        //llamo a la api para obtener todos los registros
        this.$http.get("videos.usuario/" + this.getUsuarioCapa.iderp).then((response) => {
          // al arreglo que declare previamente le asigno response.body que son todos los registros
          this.cursos = response.body;

          this.cargar = false
        }).catch( error =>{
          this.validarError( error )
        }).finally( () => { this.cargar = false })
      },

      ...mapActions("Cursos", ["guardarIdCurso", "guardarCurso"]), ///cambios

      ...mapActions("Materiales", ["guardarMaterial"]),

      openCurso(item) {
      	this.curso = item
      	this.dialogVideo = true
      },
    },
  };
</script>
