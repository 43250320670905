import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import VueResource from 'vue-resource'

import { Howl, Howler } from 'howler'

Vue.config.productionTip = false
Vue.use(VueResource)

if(process.env.NODE_ENV == 'development'){
  Vue.http.options.root = 'http://localhost:3004/';
  // Vue.http.options.root = 'https://web-back-inbi.club/kpi/'
}else{
  Vue.http.options.root = 'https://academicokpi.club/kpi/';
}


// axios.interceptors.request.use(config => config);
const datosUsuario = store.state.UsuarioCapa.datosUsuarioCapa.iderp;

Vue.http.interceptors.push((request, next) => {
  if (datosUsuario) {
    request.headers.set('user-id', String(datosUsuario));
  }
  next();
});


new Vue({
    router,
    store,
    vuetify,
    render: function(h) { return h(App) }
}).$mount('#app')
